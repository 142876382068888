<template>
    <PageLayout>
        <template>
            <div class="d-flex w-100 justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ heading }}</h1>
                </div>
            </div>
            <v-form class="filter">
                <v-card>
                    <v-card-text>
                <v-row>
                    <v-col cols="12" v-if="chartParams === 'pie'">
                        <pie-chart :chartData="JSON.stringify(listItems)" :options="optionsChartBar"></pie-chart>
                    </v-col>
                    <v-col cols="12" v-else-if="chartParams === 'line'">
                        <line-chart :chartData="JSON.stringify(listItems)" :options="optionsChartLine"></line-chart>
                    </v-col>
                    <v-col cols="12" v-else-if="chartParams === 'bar'">
                        <bar-chart :chartData="JSON.stringify(listItems)" :options="optionsChartBar"></bar-chart>
                    </v-col>
                    <v-col cols="12" v-else-if="chartParams === 'doughnut'">
                        <doughnut-chart :chartData="JSON.stringify(listItems)"></doughnut-chart>
                    </v-col>
                    <v-col cols="12" v-else-if="chartParams === 'horizontal_bar'">
                        <horizontal-bar-chart :chartData="JSON.stringify(listItems)" ></horizontal-bar-chart>
                    </v-col>
                    <v-col cols="12" v-else-if="chartParams === 'table_one_column'">
                        <ReportTableOneColumn :items="listItems"/>
                    </v-col>
                    <v-col cols="12" v-else-if="chartParams === 'block_count'">
                        <ReportBlockCount :title="'dd'" :items="listItems"/>
                    </v-col>

                    <v-col cols="12" v-else-if="(chartParams === '' || chartParams==='table') && (headers.length > 0 && listItems.length > 0 && selectedColumns.length > 0)">

                        <ReportDataTable
                            :headers="headers"
                            :items="listItems"
                            :selectedColumns="selectedColumns"
                            :sortColumn="sortColumn"
                            :sortDirection="sortDirection"
                            :groupBy="groupBy"
                        />
                    </v-col>



                </v-row>
                    </v-card-text>
                </v-card>






            </v-form>
        </template>
        <template v-slot:side>
              <v-list class="filter_left">
                <v-list-group :ripple="false" v-model="group1"  class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{$t('Настрока таблицы')}}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link >
                        <v-list-item-title class="cursor-pointer" @click="openDialog(item.dialog)" v-text="item.name"></v-list-item-title>
                        <v-list-item-icon  v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-btn color="primary" @click="dialogOpenSaveFilter()">Сохранить отчет</v-btn>

            <SelectTablesDialog  v-if="dialogTables" :tableParams="tableParams" :dialogTables="dialogTables"  @input="handleDialogClose"  />
            <SelectFilterDialog  v-if="filterData && filterData.fields && filterData.fields.length > 0" :filterData="filterData"  :dialogFilter="dialogFilter" @input="handleDialogClose"  />
            <SelectFieldsDialog  v-if="dialogFields" :fieldParams="fieldParams" :dialogFields="dialogFields"  :columns="headers" @input="handleDialogClose"  />
            <SelectSortDialog   v-if="dialogSort && sortParams" :sortParams="sortParams" :dialogSort="dialogSort"  :columns="headers" @input="handleDialogClose"  />
            <SelectGroupDialog  v-if="dialogGroup" :groupParams="groupParams" :dialogGroup="dialogGroup"  :columns="headers" @input="handleDialogClose"  />
            <SelectAggregateDialog   v-if="dialogAggregate && aggregateParams" :aggregateParams="aggregateParams" :dialogAggregate="dialogAggregate"  :columns="headers" @input="handleDialogClose"  />
            <SelectChartDialog  v-if="dialogChart" :chartParams="chartParams" :dialogCharts="dialogChart"  @input="handleDialogClose"  />

        </template>


        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialog_save_filter"
        >
            <v-form @submit.prevent="saveReportFilter">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat>
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="dialogCloseSaveFilter" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" sm="12">
                                    <ValidationProvider ref="report_filter" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="report_filter" type="text" :disabled="loading"
                                                      :error="!valid" :error-messages="errors"
                                                      :label="$t('report_name')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="px-4 pt-10 pb-7">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="dialogCloseSaveFilter"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>

    </PageLayout>




</template>
<script>

import {mapGetters} from "vuex";
import ReportDataTable from "@/components/ReportDataTable.vue";
import SelectFilterDialog from "@/components/Dialog/SelectFilter.vue";
import SelectTablesDialog from "@/components/Dialog/SelectTables.vue";
import SelectFieldsDialog from "@/components/Dialog/SelectFields.vue";
import SelectSortDialog from "@/components/Dialog/SelectSort.vue";
import SelectGroupDialog from "@/components/Dialog/SelectGroup.vue";
import SelectAggregateDialog from "@/components/Dialog/SelectAggregate.vue";
import SelectChartDialog from "@/components/Dialog/SelectCharts.vue";
import LineChart from '../plugins/charts/LineChart.js'
import BarChart from '../plugins/charts/BarChart.js'
import PieChart from '../plugins/charts/PieChart.js'
import DoughnutChart from '../plugins/charts/DoughnutChart.js'
import HorizontalBarChart from '../plugins/charts/HorizontalBarChart.js'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import ReportTableOneColumn from "../components/ReportTableOneColumn";
import ReportBlockCount from "../components/ReportBlockCount";


export default {
        name: 'CreateReportForm',
        components: {
            ValidationProvider,
            ValidationObserver,
            ReportDataTable,
            SelectFilterDialog,
            SelectTablesDialog,
            SelectFieldsDialog,
            SelectSortDialog,
            SelectGroupDialog,
            SelectAggregateDialog,
            SelectChartDialog,
            PieChart,
            LineChart,
            DoughnutChart,
            HorizontalBarChart,
            BarChart,
            ReportTableOneColumn,
            ReportBlockCount,
        },
        inject: ['forceRerender'],
        data() {
            return {
                heading: null,
                progress: 0,
                loading: false,
                language: null,
                id: null,
                tab: 0,
                exist_translations: {},
                all_translations: true,


         //       http://localhost:8080/create_report/create?group=id,files,document_status_id,author_id&sort[name]=desc&aggregate_function[name]=max&aggregate_function[id]=mix&filter[inbox][custom]=1&filter[document_type_id][in][0]=2&filter[document_type_id][in][1]=1&table[name]=document&fields=id,name,email,files,document_status_id

                headers: [],
                listItems: [],
                selectedColumns: [],
                sortColumn: 'type_name',
                sortDirection: 'asc',
                groupBy: 'files',
                filterData: [],
                group1: true,
                system_filters: [
                    {'id': 1, 'name': this.$t('select_table'), 'dialog': 'dialogTables', 'count': 0},
                    {'id': 2, 'name': this.$t('select_fields'), 'dialog': 'dialogFields', 'count': 0},
                    {'id': 3, 'name': this.$t('filters'), 'dialog': 'dialogFilter', 'count': 0},
                    {'id': 4, 'name': this.$t('sorting'), 'dialog': 'dialogSort', 'count': 0},
                    {'id': 5, 'name': this.$t('grouping'), 'dialog': 'dialogGroup', 'count': 0},
                    {'id': 6, 'name': this.$t('aggregate_function'), 'dialog': 'dialogAggregate', 'count': 0},
                    {'id': 7, 'name': this.$t('chart'), 'dialog': 'dialogChart', 'count': 0},
                ],
                dialogTables: false,
                dialogFilter: false,
                dialogFields: false,
                dialogSort: false,
                dialogGroup: false,
                dialogAggregate: false,
                dialogChart: false,
                chartParams:'',
                tableParams:null,
                fieldParams:'',
                groupParams:'',
                sortParams: {},
                aggregateParams: {},
                filterForm: {},
                dialog_save_filter: false,
                report_filter: null,
                fieldKeywords: ['count', 'min', 'max','avg','sum'],
                headersAddArray: [],
                optionsChartLine: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                //stepSize: 1,
                                beginAtZero: true,
                                callback: function (value) {
                                    if (value % 1 === 0) {
                                        return value;
                                    }
                                }
                            }
                        }]
                    },
                },
                optionsChartBar: {
                    responsive: true,
                    maintainAspectRatio: false,
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                },
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'lang']),
            languages() {
                return this.listLanguages
            },

        },

        async mounted() {
            this.language = this.languages[this.tab]
            await this.checkCreate()
        },
        methods: {
            setLanguage(val) {
                this.language = this.languages[val]
                this.checkCreate()
            },
            async checkCreate() {


                if (this.$route.name === "create_report.create") {
                    this.heading = this.$t('report_creation')
                } else {
                    this.heading = this.$t('report_editing')
                    if (this.$route.params.id) {
                        await this.getReportFilter()
                    }
                }
                this.filterForm = this.$route.query;
                await this.getReportTable()
                await this.setRouteParams();
                await this.getFields()
                await this.setCountFilter()
            },
            async getReportTable() {
                this.loading = true;

                let params = {};


                params.sortDir =  'desc';
                params.page =  1;
                params.sortBy = 'id'


                if (this.filterForm && this.filterForm.table ) {
                    params.table = this.filterForm.table;
                }
                if (this.filterForm && this.filterForm.filter) {
                    params.filter = this.filterForm.filter;
                }
                if (this.filterForm && this.filterForm.sort) {
                    params.sort = this.filterForm.sort;
                }
                if (this.filterForm && this.filterForm.fields) {
                    params.fields = this.filterForm.fields;
                }
                if (this.filterForm && this.filterForm.group) {
                    params.group = this.filterForm.group;
                }
                if (this.filterForm && this.filterForm.aggregate_function) {
                    params.aggregate_function = this.filterForm.aggregate_function;
                }
                if (this.filterForm && this.filterForm.all_values) {
                    params.all_values = this.filterForm.all_values;
                }
                if (this.filterForm && this.filterForm.time_per) {
                    params.time_per = this.filterForm.time_per;
                }
                let print_table = false;
                if (this.filterForm && this.filterForm.chart) {
                    params.chart = this.filterForm.chart;
                    if(this.filterForm.chart === 'table'){
                        print_table = true;
                    }
                }
                else {
                    print_table = true;
                }
                params.language = this.lang;
                if(params.table &&  params.table!== ''){
                    await this.$http
                        .get("admin/report_data", {
                            params: params,
                        })
                        .then(res => {
                            this.filterData = res.body.filter;
                            this.listItems = res.body.data
                            if(print_table)
                            {
                                this.listItems.forEach(item => {

                                    Object.keys(item).forEach(key => {
                                        // Найти первый подходящий keyword
                                        const matchingKeyword = this.fieldKeywords.find(keyword => key.includes(keyword));

                                        if (matchingKeyword) {
                                            // Проверяем, есть ли уже объект с таким же значением `value`
                                            const exists = this.headersAddArray.some(obj => obj.value === key);

                                            if (!exists) {
                                                this.headersAddArray.push({
                                                    width: 'auto',
                                                    value: key,
                                                    text: this.$t(matchingKeyword),
                                                    align: 'left'
                                                });
                                            }
                                        }
                                    });
                                });
                            }

                        })
                        .catch(err => {
                            this.documents = []
                            this.$toastr.error(this.$t('failed_to_get_list_documents'))
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
                //params.language = "kk";

            },
            async getFields() {
                this.loading = true;
                let params = {};
                if (this.filterForm && this.filterForm.table ) {
                    params.table = this.filterForm.table;
                }
                else if(this.tableParams && this.tableParams !== '') {
                    params.table = this.tableParams;
                }
                if (this.filterForm && this.filterForm.chart ) {
                    params.chart = this.filterForm.chart;
                }
                else if(this.chartParams && this.chartParams !== '') {
                    params.chart = this.chartParams;
                }

                if (this.filterForm && this.filterForm.fields ) {
                    params.fields = this.filterForm.fields;
                }
                else if(this.fieldParams && this.fieldParams !== '') {
                    params.fields = this.fieldParams;

                }
                params.language = this.lang;
               // params.language = "kk";
                params.language = this.lang;
                if(params.table &&  params.table!== '') {
                    await this.$http
                        .get("admin/report_fields", {
                            params: params,
                        })
                        .then(res => {
                            if (res.status === 200) {
                                this.listForm = res.body;

                                this.selectedColumns = this.listForm.filter.map(fields => fields.name);


                                this.headers = this.listForm.table.map((item) => {
                                    return {
                                        ...item,
                                        width: item.width === 0 ? 'auto' : item.width,
                                        value: item.name,
                                        text: item.label,
                                        align: 'left'
                                    }
                                });


                                this.headersAddArray.forEach(item => {
                                    const exists = this.headers.some(obj => obj.value === item.value);

                                    if (!exists) {
                                        this.headers.push(item);
                                    }
                                });


                            }
                        })
                        .catch(err => {
                            this.documents = []
                            this.$toastr.error(this.$t('failed_to_get_list_documents'))
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }

            },
            setCountFilter(){
                if (this.filterForm && this.filterForm.table ) {
                    const item = this.system_filters.find(item => item.dialog === 'dialogTables');
                    if (item) {
                        item.count = [this.filterForm.table].length;
                    }
                }
                if (this.filterForm && this.filterForm.fields) {
                    const item = this.system_filters.find(item => item.dialog === 'dialogFields');
                    if (item) {
                        item.count = this.filterForm.fields.split(',').length;
                    }
                }
                if (this.filterForm && this.filterForm.filter) {
                    const item = this.system_filters.find(item => item.dialog === 'dialogFilter');
                    if (item) {
                        item.count = Object.keys(this.filterForm.filter).length;
                    }
                }
                if (this.filterForm && this.filterForm.sort) {
                    const item = this.system_filters.find(item => item.dialog === 'dialogSort');
                    if (item) {
                        item.count = Object.keys(this.filterForm.sort).length;
                    }
                }


                if (this.filterForm && this.filterForm.group) {
                    const item = this.system_filters.find(item => item.dialog === 'dialogGroup');
                    if (item) {
                        item.count = this.filterForm.group.split(',').length;
                    }
                }
                if (this.filterForm && this.filterForm.aggregate_function) {
                    const item = this.system_filters.find(item => item.dialog === 'dialogAggregate');
                    if (item) {
                        item.count = Object.keys(this.filterForm.aggregate_function).length;
                    }
                }

                if (this.filterForm && this.filterForm.chart ) {
                    const item = this.system_filters.find(item => item.dialog === 'dialogChart');
                    if (item) {
                        item.count = [this.filterForm.chart].length;
                    }
                }
            },


            openDialog(dialog_name){
                switch (dialog_name){
                    case 'dialogTables': this.dialogTables = true; break;
                    case 'dialogFilter': this.dialogFilter = true; break;
                    case 'dialogFields': this.dialogFields = true; break;
                    case 'dialogSort': this.dialogSort = true; break;
                    case 'dialogGroup': this.dialogGroup = true; break;
                    case 'dialogAggregate': this.dialogAggregate = true; break;
                    case 'dialogChart': this.dialogChart = true; break;
                }

            },

            async handleDialogClose(params){

                this.dialogTables = false;
                this.dialogFilter = false;
                this.dialogFields = false;
                this.dialogSort = false;
                this.dialogGroup = false;
                this.dialogAggregate = false;
                this.dialogChart = false;

                this.filterForm = {
                    ...this.filterForm,
                    ...params
                };

                await this.getReportTable()
                await this.setRouteParams();
                await this.getFields()
                await this.setCountFilter()


            },


            dialogOpenSaveFilter(){
                this.dialog_save_filter = true;
            },
            dialogCloseSaveFilter(){
                this.dialog_save_filter = false;
            },
            setRouteParams(){

                if( this.$route.query &&  this.$route.query.table){
                    this.tableParams =  this.$route.query.table;
                }
                if( this.$route.query &&  this.$route.query.chart){
                    this.chartParams =  this.$route.query.chart;
                }
                if( this.$route.query &&  this.$route.query.fields){
                    this.fieldParams =  this.$route.query.fields;
                }
                if( this.$route.query &&  this.$route.query.group){
                    this.groupParams =  this.$route.query.group;
                }
                if( this.$route.query &&  this.$route.query.sort){
                    this.sortParams =  this.$route.query.sort;
                }
                if( this.$route.query &&  this.$route.query.aggregate_function){
                    this.aggregateParams =  this.$route.query.aggregate_function;
                }
            },

            async getReportFilter() {
                var _this = this;
                this.progress = 0
                this.loading = true
                let params = {}
                if (this.language) {
                    params.language = this.language
                }
                await this.$http
                    .get(`admin/report_filter/${this.$route.params.id}`, {
                        params: params,
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.report_filter = res.body.data.name
                        this.filterForm = res.body.data.filter

                        let query = {
                            ...this.$route.query,
                            ...this.filterForm
                        };

                        this.$router.push({
                            query
                        });
                        this.$route.query
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_create_report'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveReportFilter() {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()
                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])
                }

                if (this.report_filter ) {
                    formData.append('report_filter', this.report_filter)
                }

                formData.append('filter', JSON.stringify(this.filterForm))

                if (this.$route.params.id) {
                    await this.$http
                        .put(`admin/report_filter/${this.$route.params.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('report_has_been_updated'))
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('report_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                } else {
                    // Add
                    await this.$http
                        .post('admin/report_filter', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('report_has_been_added'))
                            // if (res && res.body && res.body.data && res.body.data.id) {
                            //     this.$router.push({
                            //         name: 'create_report.edit',
                            //         params: {
                            //             id: res.body.data.id
                            //         }
                            //     })
                            // } else {
                            //     this.$router.push({
                            //         name: 'create_report'
                            //     })
                            // }
                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('report_has_not_been_added'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            },



        }
    }
</script>
