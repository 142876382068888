<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="localDialog"
        class="dialog_body"
        persistent
    >
        <v-card>
            <v-toolbar class="dialog_toolbar" elevation="0">
                <div class="ma-auto ml-0">
                    <span>{{ $t('select_chart') }}</span>
                </div>
                <div>
                    <v-icon @click="closeDialogCharts()" :title="$t('close')">mdi-close</v-icon>
                </div>
            </v-toolbar>
            <v-card-text class="pa-2 pb-0">
                <v-col cols="12">
                    <SelectInput
                        clearable
                        item-text="title"
                        item-value="value"
                        hide-details
                        hide-label
                        :items="chartItems"
                        v-model="chart"
                        name="chart"
                        id="chart"
                        :hideLabel="false"
                        :label="$t('chart_name')"
                    ></SelectInput>
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <Btn
                    @click="selectChart()"
                    class="mobile-filter__link"
                >
                    {{ $t('OK') }}
                </Btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import SelectInput from "@/components/Form/SelectInput.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "SelectCharts",
    components: {
        SelectInput,
        Btn
    },
    props: {
        dialogCharts: {
            type: Boolean,
            required: true
        },
        chartParams: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            localDialog: this.dialogCharts,
            chart: null,
            chartItems: [],
        }
    },
    watch: {
        dialogCharts(newVal) {
            this.localDialog = newVal;
        },
    },
    async mounted() {
        await this.getCharts();
    },
    methods: {
        async getCharts() {
            this.chartItems = [
                { value: 'pie', title: this.$t('pie') },
                { value: 'line', title: this.$t('line') },
                { value: 'bar', title: this.$t('bar') },
                { value: 'doughnut', title:  this.$t('doughnut') },
                { value: 'horizontal_bar', title:  this.$t('horizontal_bar') },
                { value: 'table', title:  this.$t('table') },
                { value: 'table_one_column', title:  this.$t('table_one_column') },
                { value: 'block_count', title:  this.$t('block_count') },
            ];
            this.chart = this.chartParams;
        },
        closeDialogCharts() {
            this.localDialog = false;
            this.$emit('input', {'chart': this.chart});
            this.chart = null;
        },
        selectChart() {



                let query = {
                    ...this.$route.query,
                    chart:this.chart,
                };
                this.$router.push({ query });


            this.closeDialogCharts();
        }
    }
}
</script>

<style lang="scss">
/* Ваши стили */
</style>
