<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="900"
        v-model="localDialog"
        class="dialog_body"
        persistent

    >
        <v-card>
            <v-toolbar class="dialog_toolbar" elevation="0">
                <div class="ma-auto ml-0">
                    <span>{{ $t('filters') }}</span>
                </div>
                <div>
                    <v-icon @click="closeDialogFilter()" :title="$t('close')">mdi-close</v-icon>
                </div>
            </v-toolbar>
            <v-card-text class="pa-2 pb-0">

                <div>


                        <!-- Перебор выбранных полей -->
                        <template v-for="(fieldName, key) in selectedFields">
                            <template v-for="(field, k) in filterData.fields">
                                <template v-if="field.name === fieldName">
                                    <FilterField
                                        class="mb-3 mr-5"
                                        :key="key +'_'+ k"
                                        v-model="fields[fieldName]['value']"
                                        @changeOperand="onOperandChange(fieldName, $event)"
                                        @onDeleteField="deleteField"
                                        :name="fieldName"
                                        :field="field"
                                        :operand="fields[fieldName]['operand']"
                                    />
                                </template>
                            </template>
                        </template>

                        <div class="f-flex mb-5">
                            <div v-if="availableFields.length > 0" class="pt-0">
                                <SelectInput
                                    outlined
                                    solo
                                    flat
                                    dense
                                    id="filter__select-field"
                                    item-value="value"
                                    item-text="text"
                                    v-model="selectedFields"
                                    :items="availableFields"
                                    :label="$t('filter_add_field_select')"
                                    multiple
                                ></SelectInput>
                            </div>
                        </div>

                    <div class="f-flex mb-5">
                        <div v-if="availableFields.length > 0" class="pt-0">
                            <SelectInput
                                outlined
                                solo
                                flat
                                dense
                                id="all_values"
                                item-value="value"
                                item-text="text"
                                v-model="all_values"
                                :items="availableFields"
                                :label="$t('filter.field.all_values')"
                                clerable
                            ></SelectInput>
                        </div>
                    </div>

                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>



                <Btn

                    :disabled="loading"
                    color="primary"
                    @click="submitForm"
                >
                    {{ $t('filter_apply') }}
                </Btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>


import FilterField from "@/components/FilterField.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import _ from "lodash";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: 'SelectFilter',
    components: {
        FilterField,
        SelectInput,
        Btn
    },
    props: {
        dialogFilter: {
            type: Boolean,
            required: true,
            default: false,
        },
        filterData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            localDialog: this.dialogFilter,
            progress: 0,
            loading: false,

            selectedFields: [],
            all_values: [],
            fields: {},
            value: {},

        }
    },
    computed: {
        availableFields() {
            let availableFields = [];

            for (let i in this.filterData.fields) {
                let field = this.filterData.fields[i];

                availableFields.push({
                    text: this.$t('filter.field.' + field.label),
                    value: field.name,
                });
            }

            return availableFields;
        },
    },
    watch: {
        dialogFilter(newVal) {
            this.localDialog = newVal;
        },
        filterData: {
            handler() {
                this.fields = this.getFields();
            },
            immediate: true,
        },

        currentFilter: {
            handler() {
                if (this.currentFilter) {
                    this.filterName = this.currentFilter.name;
                    this.selectedFields = _.uniq(this.selectedFields.concat(this.currentFilter.fields));
                }
            },
            deep: true
        },
        value: {
            handler() {
                this.selectedFields = _.uniq(this.selectedFields.concat(Object.keys(this.value)));
            },
            deep: true,
        }
    },
    beforeMount() {
        this.processQuery();
    },
    mounted() {
        if (this.filterData && Object.keys(this.filterData).length) {
            this.fields = this.getFields();
        }
    },
    methods: {

        closeDialogFilter(){
            this.localDialog = false;
        },
        selectFilter() {

            this.closeDialogTables();
        },

        getFields() {
            let fieldsValues = {};
            for (let i in this.filterData.fields) {
                let field = this.filterData.fields[i];

                if (field.operands.length === 0) {
                    continue;
                }
                let filterValue = {
                    value: '',
                    operand: _.first(field.operands)['key']
                }

                if (this.value[field.name]) {
                    let operand = _.first(_.keys(this.value[field.name]));

                    filterValue = {
                        value: this.value[field.name][operand],
                        operand: operand
                    }
                }

                fieldsValues[field.name] = filterValue;

            }
            return fieldsValues;
        },
        deleteField(fieldName) {
            this.selectedFields = _.without(this.selectedFields, fieldName);
            this.getValue();
        },
        deleteFilter() {
            this.$http.delete('admin/' + this.entity + '/filter/' + this.currentFilter.id)
                .then(function () {
                    this.$toastr.success(this.$t('filter_deleted'));
                    this.$emit('filterDeleted');
                });
        },
        onOperandChange(fieldName, value) {
            this.fields[fieldName]['operand'] = value
        },
        getValue() {
            let value = {};
            for (let i in this.fields) {
                let fieldValue = this.fields[i];
                let field = this.filterData.fields.find(f => f.name === i);

                if (
                    fieldValue.value !== undefined
                    && fieldValue.value !== null
                    && fieldValue.value !== ''
                    && this.selectedFields.indexOf(i) !== -1
                ) {
                    value[field.name] = {};
                    value[field.name][fieldValue.operand] = fieldValue.value
                }
            }
            return value;
        },
        submitForm() {
            let value = this.getValue();

            let query = {
                ...this.$route.query,
                filter: value,
                ...(this.all_values ? { all_values: this.all_values } : {})
            };



            this.$emit('input', {filter:value, all_values: this.all_values});

            if (this.currentFilter) {
                query.filterId = this.currentFilter.id;
            }

            this.$router.push({
                query
            });

        },
        processQuery() {
            if (
                this.$route.query
                && this.$route.query.filter
                && _.isPlainObject(this.$route.query.filter)
            ) {
                this.value =  this.$route.query.filter
            } else {
                this.value = {};
            }

            let itemsPerPage = parseInt(this.$route.query.itemsPerPage);
            if (itemsPerPage) {
                this.options.itemsPerPage = itemsPerPage;
                this.setSettings({
                    ...this.$auth.user().settings,
                    itemsPerPage: itemsPerPage
                })
            }

        },




    }
}
</script>



