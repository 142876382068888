<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="localDialog"
        class="dialog_body"
        persistent
    >
        <v-card>
            <v-toolbar class="dialog_toolbar" elevation="0">
                <div class="ma-auto ml-0">
                    <span>{{ $t('aggregate_function') }}</span>
                </div>
                <div>
                    <v-icon @click="closeDialogAggregate()" :title="$t('close')">mdi-close</v-icon>
                </div>
            </v-toolbar>
            <v-card-text class="pa-2 pb-0">
                <v-col cols="12">
                <template v-for="(fieldName, key) in aggregateParams">
                    <div class="filter-field"  :key="key">
                        <div class="filter-field__label"><label class="input_label" :for="'column_'+key">{{ $t('filter.field.' + key) }}</label></div>
                        <div class="filter-field__inner">
                            <div class="filter-field__operand">

                                <SelectInput
                                    item-value="value"
                                    item-text="label"
                                    clearable
                                    hide-details
                                    hide-label
                                    :items="columns"
                                    :v-model="key"
                                    :name="'column_'+key"
                                    :id="'column_'+key"
                                    :value="key"
                                ></SelectInput>
                            </div>
                            <div class="filter-field__input">
                                <SelectInput
                                    clearable
                                    hide-details
                                    hide-label
                                    :items="direction"
                                    :v-model="fieldName"
                                    :name="'direction'+key"
                                    :id="'direction'+key"
                                    :value="fieldName"
                                    @input="updateDirection($event, key)"
                                ></SelectInput>
                            </div>
                            <div class="filter-field__delete">
                                <v-btn
                                    icon
                                    @click="deleteColumn(key)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </div>
                        </div>

                    </div>
                </template>
                </v-col>


                <v-col cols="12">
                    <SelectInput
                        item-value="value"
                        item-text="label"
                        multiple
                        clearable
                        hide-details
                        hide-label
                        :items="columns"
                        v-model="aggregateColumns"
                        name="columns"
                        id="aggregate_column"
                        :hideLabel="false"
                        :label="$t('column_name')"
                        @input="updateColumns"
                    ></SelectInput>
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <Btn
                    @click="selectColumn()"
                    class="mobile-filter__link"
                >
                    {{ $t('OK') }}
                </Btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import SelectInput from "@/components/Form/SelectInput.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "SelectAggregate",
    components: {
        SelectInput,
        Btn
    },
    props: {
        dialogAggregate: {
            type: Boolean,
            required: true
        },
        columns: {
            type: Array,
            required: true,
        },
        aggregateParams: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            localDialog: this.dialogAggregate,
            aggregateColumns: Object.keys(this.aggregateParams),
            direction: [
                {'value': 'min_max_avg' , 'text': this.$t('min_max_avg')},
                {'value': 'max' , 'text': this.$t('maximum_value')},
                {'value': 'min' , 'text': this.$t('minimum_value')},
                {'value': 'avg' , 'text': this.$t('avg_value')},
                {'value': 'count' , 'text': this.$t('quantity')},
                {'value': 'sum' , 'text': this.$t('amount')},
            ]
        }
    },
    watch: {
        dialogAggregate(newVal) {
            this.localDialog = newVal;
        },
    },
    methods: {
        updateDirection(fieldName, key){
            if (Object.prototype.hasOwnProperty.call(this.aggregateParams, key)) {
                this.$set(this.aggregateParams, key, fieldName);
            }

        },
        deleteColumn(key){
            if (Object.prototype.hasOwnProperty.call(this.aggregateParams, key)) {
                this.$delete(this.aggregateParams, key);  // Удаляем свойство реактивно
            }

            const index = this.aggregateColumns.indexOf(key);
            if (index !== -1) {
                this.aggregateColumns.splice(index, 1);
            }
        },
        updateColumns(){
            // Добавляем недостающие ключи из aggregateColumns в aggregateParams
            this.aggregateColumns.forEach(column => {
                if (!Object.prototype.hasOwnProperty.call(this.aggregateParams, column)) {
                    this.aggregateParams[column] = null;
                }
            });

            // Удаляем ключи из aggregateParams, которые не присутствуют в aggregateColumns
            Object.keys(this.aggregateParams).forEach(key => {
                if (!this.aggregateColumns.includes(key)) {
                    delete this.aggregateParams[key];
                }
            });

        },
        closeDialogAggregate() {
            this.localDialog = false;
           // this.$emit('input', this.aggregateParams);
        },
        async selectColumn() {

            let value = await this.createNewQuery();
            const timestamp = Math.floor(Date.now() / 1000);

            let updatedQuery = { ...this.$route.query };

            // Удаляем старый параметр aggregate, если он существует
            delete updatedQuery.aggregate;

            // Удаляем старый параметр, если он существует
            for (const key in updatedQuery) {
                if (key.startsWith('filter_update_')) {
                    delete updatedQuery[key];
                }
            }

            updatedQuery = {
                ...updatedQuery,
                aggregate_function: value,
                [`filter_update_${timestamp}`]: timestamp
            }

            this.$emit('input', {aggregate_function:value});
            // Обновляем URL с новыми параметрами
            await this.$router.push({ query: updatedQuery });

            this.closeDialogAggregate()

        },
         createNewQuery() {
            let newQuery = {};

            // Используем цикл for...of для перебора пар ключ-значение
            for (const [key, value] of Object.entries(this.aggregateParams)) {
                newQuery[key] = value;
            }

            return newQuery;
        }


    }
}
</script>

<style lang="scss">
.filter-field {
    display: flex;
    flex-direction: column;
    margin-left: $grid-gutter / -2;
    margin-right: $grid-gutter / -2;
}

.filter-field__input {
    flex-grow: 1;
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.filter-field__label {
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.filter-field__operand {
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.filter-field__inner {
    flex-grow: 1;
    display: flex;
}

.filter-field__label {

}
</style>
