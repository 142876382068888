<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="localDialog"
        class="dialog_body"
        persistent
    >
        <v-card>
            <v-toolbar class="dialog_toolbar" elevation="0">
                <div class="ma-auto ml-0">
                    <span>{{ $t('grouping') }}</span>
                </div>
                <div>
                    <v-icon @click="closeDialogColumn()" :title="$t('close')">mdi-close</v-icon>
                </div>
            </v-toolbar>
            <v-card-text class="pa-2 pb-0">
                <v-col cols="12">
                    <SelectInput
                        tem-value="value"
                        item-text="label"
                        multiple
                        clearable
                        hide-details
                        hide-label
                        :items="columns"
                        v-model="groupColumn"
                        name="columns"
                        id="column"
                        :hideLabel="false"
                        :label="$t('column_name')"
                    ></SelectInput>
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <Btn
                    @click="selectColumn()"
                    class="mobile-filter__link"
                >
                    {{ $t('OK') }}
                </Btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import SelectInput from "@/components/Form/SelectInput.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "SelectGroup",
    components: {
        SelectInput,
        Btn
    },
    props: {
        dialogGroup: {
            type: Boolean,
            required: true
        },
        columns: {
            type: Array,
            required: true,
        },
        groupParams: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            localDialog: this.dialogGroup,
            groupColumn: this.groupParams.split(","),
        }
    },
    watch: {
        dialogGroup(newVal) {
            this.localDialog = newVal;
        },
    },
    methods: {
        closeDialogColumn() {
            this.localDialog = false;
        //    this.$emit('input', this.groupColumn);
            this.groupColumn = null;
        },
        selectColumn() {

            let query = {
                ...this.$route.query,
                group: this.groupColumn.filter(param => param !== "").join(",")
            };

            this.$router.push({
                query
            });

            this.$emit('input', {group:this.groupColumn.filter(param => param !== "").join(",")});
            this.closeDialogColumn();
        }
    }
}
</script>

<style lang="scss">
/* Ваши стили */
</style>
