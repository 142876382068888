<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="localDialog"
        class="dialog_body"
        persistent
    >
        <v-card>
            <v-toolbar class="dialog_toolbar" elevation="0">
                <div class="ma-auto ml-0">
                    <span>{{ $t('sorting') }}</span>
                </div>
                <div>
                    <v-icon @click="closeDialogSort()" :title="$t('close')">mdi-close</v-icon>
                </div>
            </v-toolbar>
            <v-card-text class="pa-2 pb-0">
                <v-col cols="12">
                <template v-for="(fieldName, key) in sortParams">
                    <div class="filter-field"  :key="key">
                        <div class="filter-field__label"><label class="input_label" :for="'column_'+key">{{ $t('filter.field.' + key) }}</label></div>
                        <div class="filter-field__inner">
                            <div class="filter-field__operand">

                                <SelectInput
                                    item-value="value"
                                    item-text="label"
                                    clearable
                                    hide-details
                                    hide-label
                                    :items="columns"
                                    :v-model="key"
                                    :name="'column_'+key"
                                    :id="'column_'+key"
                                    :value="key"
                                ></SelectInput>
                            </div>
                            <div class="filter-field__input">
                                <SelectInput
                                    clearable
                                    hide-details
                                    hide-label
                                    :items="direction"
                                    :v-model="fieldName"
                                    :name="'direction'+key"
                                    :id="'direction'+key"
                                    :value="fieldName"
                                    @input="updateDirection($event, key)"
                                ></SelectInput>
                            </div>
                            <div class="filter-field__delete">
                                <v-btn
                                    icon
                                    @click="deleteColumn(key)"
                                >
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </div>
                        </div>

                    </div>
                </template>
                </v-col>


                <v-col cols="12">
                    <SelectInput
                        item-value="value"
                        item-text="label"
                        multiple
                        clearable
                        hide-details
                        hide-label
                        :items="columns"
                        v-model="sortColumns"
                        name="columns"
                        id="sort_column"
                        :hideLabel="false"
                        :label="$t('column_name')"
                        @input="updateColumns"
                    ></SelectInput>
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <Btn
                    @click="selectColumn()"
                    class="mobile-filter__link"
                >
                    {{ $t('OK') }}
                </Btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import SelectInput from "@/components/Form/SelectInput.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "SelectSort",
    components: {
        SelectInput,
        Btn
    },
    props: {
        dialogSort: {
            type: Boolean,
            required: true
        },
        columns: {
            type: Array,
            required: true,
        },
        sortParams: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            localDialog: this.dialogSort,
            sortColumns: Object.keys(this.sortParams),
            direction: [
                {'value': 'asc' , 'text': 'Вверх'},
                {'value': 'desc' , 'text': 'Вниз'},
            ]
        }
    },
    watch: {
        dialogSort(newVal) {
            this.localDialog = newVal;
        },
    },
    methods: {
        updateDirection(fieldName, key){
            if (Object.prototype.hasOwnProperty.call(this.sortParams, key)) {
                this.$set(this.sortParams, key, fieldName);
            }

        },
        deleteColumn(key){
            if (Object.prototype.hasOwnProperty.call(this.sortParams, key)) {
                this.$delete(this.sortParams, key);  // Удаляем свойство реактивно
            }

            const index = this.sortColumns.indexOf(key);
            if (index !== -1) {
                this.sortColumns.splice(index, 1);
            }
        },
        updateColumns(){
            // Добавляем недостающие ключи из sortColumns в sortParams
            this.sortColumns.forEach(column => {
                if (!Object.prototype.hasOwnProperty.call(this.sortParams, column)) {
                    this.sortParams[column] = null;
                }
            });

            // Удаляем ключи из sortParams, которые не присутствуют в sortColumns
            Object.keys(this.sortParams).forEach(key => {
                if (!this.sortColumns.includes(key)) {
                    delete this.sortParams[key];
                }
            });

        },
        closeDialogSort() {
            this.localDialog = false;
            this.$emit('input', this.sortParams);
        },
        async selectColumn() {

            let value = await this.createNewQuery();
            const timestamp = Math.floor(Date.now() / 1000);

            let updatedQuery = { ...this.$route.query };

            // Удаляем старый параметр sort, если он существует
            delete updatedQuery.sort;

            // Удаляем старый параметр, если он существует
            for (const key in updatedQuery) {
                if (key.startsWith('filter_update_')) {
                    delete updatedQuery[key];
                }
            }

            updatedQuery = {
                ...updatedQuery,
                sort: value,
                [`filter_update_${timestamp}`]: timestamp
            }

            this.$emit('input', {sort:value});

            // Обновляем URL с новыми параметрами
            await this.$router.push({ query: updatedQuery });

            this.closeDialogSort()

        },
         createNewQuery() {
            let newQuery = {};

            // Используем цикл for...of для перебора пар ключ-значение
            for (const [key, value] of Object.entries(this.sortParams)) {
                newQuery[key] = value;
            }

            return newQuery;
        }


    }
}
</script>

<style lang="scss">
.filter-field {
    display: flex;
    flex-direction: column;
    margin-left: $grid-gutter / -2;
    margin-right: $grid-gutter / -2;
}

.filter-field__input {
    flex-grow: 1;
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.filter-field__label {
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.filter-field__operand {
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.filter-field__inner {
    flex-grow: 1;
    display: flex;
}

.filter-field__label {

}
</style>
